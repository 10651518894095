import React, {useContext, useEffect, useReducer, useCallback} from 'react';

import {api} from '@utils/api';
import {getCartTotal} from '@utils/service';
import {useApp} from '@context/AppProvider';
import {reducer} from '@reducers/checkout';
import {cartData} from '@utils/functions';

const CartContext = React.createContext();
const init = {
	loading: true,
	settings: {},
	items: [],
	address: {},
	shipping: [],
	goodCause: {},
	promo: {},
	total: {},
	charityId: null,
	card: null,
	cartCount: 0,
	wishlistCount: 0,
	orderPlacing: false,
	error: {},
	showShipping: true,
	shippingLoading: false,
	is18Plus: false,
	isVerified18Plus: false,
	currentDate: '',
};

export const CartProvider = ({children}) => {
	const [state, dispatch] = useReducer(reducer, init);
	const {
		idb: {session, user},
	} = useApp();

	const loadCart = useCallback(async () => {
		if (session) {
			dispatch({
				type: 'INIT_CART',
				data: {
					loading: true,
				},
			});

			const res = await api(
				{
					url: '/cart',
					method: 'GET',
				},
				session,
			);
			let obj = {
				loading: false,
				settings: res.settings,
				...cartData(res),
			};
			if (!user) {
				let guest = JSON.parse(sessionStorage.getItem('guestCheckout'));
				delete obj.address;
				if (guest) {
					obj = {
						...obj,
						...guest,
					};
				}
			}
			dispatch({
				type: 'SET_DATA',
				data: obj,
			});
		}
	}, [session, user]);

	const updateCartCount = cartCount =>
		dispatch({
			type: 'SET_DATA',
			data: {
				cartCount,
			},
		});

	const updateWishlistCount = wishlistCount =>
		dispatch({
			type: 'SET_DATA',
			data: {
				wishlistCount,
			},
		});
	const fetchWishlistCount = useCallback(async () => {
		if (session) {
			const result = await getCartTotal({session});
			if (result.status) {
				dispatch({
					type: 'SET_DATA',
					data: {
						wishlistCount: result.wishlistCount,
					},
				});
			}
		}
	}, [session]);
	useEffect(() => {
		if (session) {
			const fetchCount = async () => {
				const result = await getCartTotal({session});
				if (result.status) {
					dispatch({
						type: 'SET_DATA',
						data: {
							cartCount: result.count,
							wishlistCount: result.wishlistCount,
						},
					});
				}
			};
			fetchCount();
		}
	}, [session]);

	const clearCart = useCallback(() => {
		dispatch({
			type: 'CLEAR_CART',
			data: init,
		});
	}, []);
	return (
		<CartContext.Provider
			value={{
				cartCount: state.cartCount,
				wishlistCount: state.wishlistCount,
				state,
				dispatch,
				updateCartCount,
				updateWishlistCount,
				loadCart,
				fetchWishlistCount,
				clearCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export function useCart() {
	const context = useContext(CartContext);
	if (context === undefined) {
		throw new Error('useCart must be used within an CartProvider');
	}
	return context;
}
