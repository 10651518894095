import React, {useContext} from 'react';

const CategoryContext = React.createContext();
import {listToTree} from '@utils/functions';

export const CategoryProvider = ({children, data}) => {
	const categories = listToTree(data, false);

	return (
		<CategoryContext.Provider
			value={{
				categories,
			}}
		>
			{children}
		</CategoryContext.Provider>
	);
};

export function useCategory() {
	const context = useContext(CategoryContext);
	if (context === undefined) {
		throw new Error('useApp must be used within an AppProvider');
	}
	return context;
}
