import React, {useState, useContext, useEffect} from 'react';

import {useApp} from '@context/AppProvider';

const GoodCauseContext = React.createContext();

export const GoodCauseProvider = ({children}) => {
	const {
		idb: {user, logoutUser},
	} = useApp();

	const [gc, setGC] = useState({
		_id: null,
		name: null,
	});
	const updateGoodCause = val => setGC(val);

	useEffect(() => {
		if (user && user.charity && user.charity._id) {
			setGC({
				_id: user.charity._id,
				name: user.charity.registeredCharityName,
			});
		}
	}, [user]);

	useEffect(() => {
		if (logoutUser) {
			setGC({
				_id: null,
				name: null,
			});
		}
	}, [logoutUser]);

	useEffect(() => {
		const isGuest = JSON.parse(sessionStorage.getItem('guestCheckout'));
		if (isGuest && isGuest.selectedCharity) {
			setGC({
				_id: isGuest.selectedCharity._id,
				name: isGuest.selectedCharity.registeredAbnName,
			});
		}
	}, []);

	return (
		<GoodCauseContext.Provider
			value={{
				gc,
				updateGoodCause,
			}}
		>
			{children}
		</GoodCauseContext.Provider>
	);
};

export function useGoodCause() {
	const context = useContext(GoodCauseContext);
	if (context === undefined) {
		throw new Error('useGoodCause must be used within an GoodCauseProvider');
	}
	return context;
}
