import {api} from '@utils/api';
import {getCurrencyByIsoCode} from '@utils/currencyFlags';

export const COUNTRY_PHONE = {
	AU: '041 123 4567',
	NZ: '021 123 4567',
};

export const getCountryList = async session => {
	try {
		const response = await api(
			{
				url: `/country`,
				method: 'get',
			},
			session,
		);
		return response;
	} catch (e) {
		return [];
	}
};

export const getCountryFlag = isoCode => {
	if (isoCode) {
		const result = getCurrencyByIsoCode(isoCode);
		if (result && result.code) {
			return `/assets/flags/${result.code.toLowerCase()}.png`;
		}
	}
	return '/assets/images/phone_icon.png';
};
