import {calculateCharityAmount} from '@utils/functions';

export const reducer = (state, action) => {
	switch (action.type) {
		case 'INIT_CART':
			return {...state, ...action.data};
		case 'SET_DATA': {
			const newState = {...state, ...action.data, error: null};
			newState.goodCause = gcTotal(
				newState.items,
				newState.shipping,
				newState.settings,
				newState.promo,
				newState.address,
				newState.card,
				newState.charityId,
			);
			return newState;
		}
		case 'SET_CHARITY':
		case 'SET_CARD':
		case 'SET_ERROR': {
			const newState = {...state, ...action.data};
			newState.goodCause = gcTotal(
				newState.items,
				newState.shipping,
				newState.settings,
				newState.promo,
				newState.address,
				newState.card,
				newState.charityId,
			);
			return newState;
		}
		default:
			return state;
	}
};

const gcTotal = (
	products,
	selectedShippings,
	settings,
	promo,
	address,
	card,
	charityId,
) => {
	let rp = {},
		retailerGcTotal = {},
		amount = 0,
		transactionFee = {},
		referrerAmt = {},
		cardCountry = card && card.country ? card.country : 'AU',
		voucherAmount = promo && promo.discount ? promo.discount : 0;

	if (products) {
		for (let i = 0; i < products.length; i++) {
			const p = products[i];
			if (rp[p.retailer_id]) {
				rp[p.retailer_id].push(p);
			} else {
				rp[p.retailer_id] = [p];
			}
		}
	}
	//console.log({products})
	for (let i = 0; i < Object.keys(rp).length; i++) {
		const retailer = rp[Object.keys(rp)[i]];
		let btFee = 0,
			productTotal = 0,
			country = address.country || 'AU',
			retailerDonation = null,
			btTransactionFee = settings.default_bt_fee || 10,
			retailerDonationAmount = 0,
			shop_name = null,
			retailerCharityName = null,
			retailerCharityId = null;
		const amtKey = 'total_amount';
		let endDate = null;
		let startDate = null;

		if (
			retailer &&
			retailer.length &&
			retailer[0].product_data.retailer &&
			retailer[0].product_data.retailer.charity
		) {
			shop_name = retailer[0].product_data.retailer.shop_name;
			retailerCharityName =
				retailer[0].product_data.retailer.charity.registeredAbnName;
			retailerCharityId = retailer[0].product_data.retailer.charity._id;
			endDate = retailer[0].product_data.retailer.retailerDonation.end_date;
			startDate = retailer[0].product_data.retailer.retailerDonation.start_date;

			retailerDonation =
				retailer[0].product_data.retailer.retailerDonation.donation_value;
		}
		for (let r = 0; r < retailer.length; r++) {
			const p = retailer[r];
			let tFee = settings.default_bt_fee || 10;
			if (
				p.product_data.retailer_products.transaction_charge_option &&
				p.product_data.retailer_products.transaction_charge_percentage
			) {
				tFee = +p.product_data.retailer_products.transaction_charge_percentage;
			} else if (p.product_data.retailer.transactionChargePercentage) {
				tFee = +p.product_data.retailer.transactionChargePercentage;
			}
			productTotal += p[amtKey];
			btTransactionFee = tFee;
			btFee += Math.round(p[amtKey] * (tFee / 100) * 100) / 100;
		}

		const extraFee = parseFloat(btFee * 0.1);
		let totalFee = parseFloat(btFee + Math.round(extraFee * 100) / 100);
		totalFee = Math.round(totalFee * 100) / 100;
		transactionFee[retailer[0].retailer_id] = totalFee;

		const retailerShipping = selectedShippings
			? selectedShippings.find(i => i.retailer_id === retailer[0].retailer_id)
			: null;
		const retailerShippingTotal = retailerShipping ? retailerShipping.value : 0;
		const totalIncludingShipping = productTotal + retailerShippingTotal;

		if (
			retailer &&
			retailer.length &&
			retailer[0].product_data.retailer &&
			retailer[0].product_data.retailer.charity
		) {
			retailerDonationAmount =
				(totalIncludingShipping *
					retailer[0].product_data.retailer.retailerDonation.donation_value) /
				100;
		}

		let totalGc = calculateCharityAmount(
			btFee,
			Object.keys(rp).length,
			totalIncludingShipping,
			voucherAmount,
			retailerShippingTotal,
			settings.australia_stripe_fee,
			settings.international_stripe_fee,
			settings.good_cause_percentage,
			country,
			cardCountry,
			retailerDonation,
		);

		retailerGcTotal[retailer[0].retailer_id] = {
			totalGc,
			retailerDonation,
			charityId,
			startDate,
			endDate,
			btTransactionFee,
			shop_name,
			retailerCharityId,
			retailerCharityName,
			retailerDonationAmount,
		};

		referrerAmt[retailer[0].retailer_id] = retailerDonationAmount.toFixed(2);
		amount += totalGc;
	}

	if (promo && promo.donate) {
		amount += promo.donate;
	}

	return {
		amount: Math.round(amount * 100) / 100,
		retailerGcTotal,
		transactionFee,
		referrerAmt,
	};
};
