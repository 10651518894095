import Image from 'next/image';

import styles from '@styles/imageWrapper/images.module.scss';

const Loading = () => (
	<div
		className={`modal fade custom-popup show ${styles.loadingModal}`}
		id='loader'
		role='dialog'
	>
		<div className={`modal-dialog ${styles.modalDialog}`}>
			<div className={`modal-content ${styles.modalContent}`}>
				<div className={`modal-body ${styles.cardBody}`}>
					<div className={styles.image}>
						<Image layout='fill' src='/img/mainLoader.gif' alt='Image' />
					</div>
				</div>
			</div>
		</div>
	</div>
);

const ProductList = props => {
	const length = props.length || 1;
	return (
		<div className='row'>
			{[...new Array(length)].map((i, key) => (
				<div className={`customCol`} key={key}>
					<svg
						viewBox='0 0 300 400'
						preserveAspectRatio='xMidYMid meet'
						primary='#f2f2f2'
						secondary='#dadada'
						data-v-bec55d12
					>
						<rect
							clipPath='url(#clipPath-42)'
							x='0'
							y='0'
							width='300'
							height='400'
							style={{fill: `url("#gradient-42")`}}
						></rect>
						<defs>
							<clipPath id='clipPath-42'>
								<rect x='0' y='0' rx='3' ry='3' width='300' height='380'></rect>
							</clipPath>
							<linearGradient id='gradient-42'>
								<stop offset='50%' stopColor='#f2f2f2'>
									<animate
										attributeName='offset'
										values='-1.5; 1.5'
										dur='2s'
										repeatCount='indefinite'
									></animate>
								</stop>
							</linearGradient>
						</defs>
					</svg>
				</div>
			))}
		</div>
	);
};

const ListItem = props => {
	const length = props.length || 1;
	let id = (Math.random() + 1).toString(36).substring(7);
	return (
		<ul>
			{[...new Array(length)].map((i, key) => (
				<li key={key}>
					<svg
						viewBox='0 0 160 30'
						preserveAspectRatio='xMidYMid meet'
						primary='#f2f2f2'
						secondary='#dadada'
						data-v-bec55d12
					>
						<rect
							clipPath={`url(#clip-${id})`}
							x='0'
							y='0'
							width='100%'
							height='30'
							style={{fill: `url("#gt-${id}")`}}
						></rect>
						<defs>
							<clipPath id={`clip-${id}`}>
								<rect
									x='10'
									y='5'
									rx='2'
									ry='2'
									width='100%'
									height='25'
								></rect>
							</clipPath>
							<linearGradient id={`gt-${id}`}>
								<stop offset='50%' stopColor='#dadada'>
									<animate
										attributeName='offset'
										values='-1.5; 1.5'
										dur='2s'
										repeatCount='indefinite'
									></animate>
								</stop>
							</linearGradient>
						</defs>
					</svg>
				</li>
			))}
		</ul>
	);
};

const LoaderThree = () => {
	return (
		<div className={styles.loaderThree}>
			<Image layout='fill' src='/assets/images/loader3.gif' alt='close-icon' />
		</div>
	);
};

const LineLoader = () => {
	return (
		<div className='loader--style6 text-center' title='5'>
			<svg
				viewBox='0 0 24 30'
				width='45px'
				height='40px'
				preserveAspectRatio='xMidYMid meet'
				primary='#f2f2f2'
				secondary='#dadada'
			>
				<rect x='0' y='13' width='4' height='5' fill='#333'>
					<animate
						attributeName='height'
						attributeType='XML'
						values='5;21;5'
						begin='0s'
						dur='0.6s'
						repeatCount='indefinite'
					/>
					<animate
						attributeName='y'
						attributeType='XML'
						values='13; 5; 13'
						begin='0s'
						dur='0.6s'
						repeatCount='indefinite'
					/>
				</rect>
				<rect x='10' y='13' width='4' height='5' fill='#333'>
					<animate
						attributeName='height'
						attributeType='XML'
						values='5;21;5'
						begin='0.15s'
						dur='0.6s'
						repeatCount='indefinite'
					/>
					<animate
						attributeName='y'
						attributeType='XML'
						values='13; 5; 13'
						begin='0.15s'
						dur='0.6s'
						repeatCount='indefinite'
					/>
				</rect>
				<rect x='20' y='13' width='4' height='5' fill='#333'>
					<animate
						attributeName='height'
						attributeType='XML'
						values='5;21;5'
						begin='0.3s'
						dur='0.6s'
						repeatCount='indefinite'
					/>
					<animate
						attributeName='y'
						attributeType='XML'
						values='13; 5; 13'
						begin='0.3s'
						dur='0.6s'
						repeatCount='indefinite'
					/>
				</rect>
			</svg>
		</div>
	);
};

const LoaderTwo = () => {
	return (
		<div className={styles.loaderTwo}>
			<Image layout='fill' src='/assets/images/loader2.gif' alt='close-icon' />
		</div>
	);
};

const LoaderFour = props => {
	const length = props.length || 1;
	return (
		<div className='row'>
			{[...new Array(length)].map((i, key) => (
				<div
					className='col-6 col-md-4 col-lg-3'
					style={{paddingTop: '20px'}}
					key={key}
				>
					<svg
						viewBox='0 0 300 400'
						preserveAspectRatio='xMidYMid meet'
						primary='#f2f2f2'
						secondary='#dadada'
						data-v-bec55d12
					>
						<rect
							clipPath='url(#clipPath-42)'
							x='0'
							y='0'
							width='300'
							height='400'
							style={{fill: `url("#gradient-42")`}}
						></rect>
						<defs>
							<clipPath id='clipPath-42'>
								<rect x='0' y='0' rx='3' ry='3' width='300' height='380'></rect>
							</clipPath>
							<linearGradient id='gradient-42'>
								<stop offset='50%' stopColor='#f2f2f2'>
									<animate
										attributeName='offset'
										values='-1.5; 1.5'
										dur='2s'
										repeatCount='indefinite'
									></animate>
								</stop>
							</linearGradient>
						</defs>
					</svg>
				</div>
			))}
		</div>
	);
};

Loading.ProductList = ProductList;
Loading.ListItem = ListItem;
Loading.LoaderThree = LoaderThree;
Loading.LoaderTwo = LoaderTwo;
Loading.LineLoader = LineLoader;
Loading.LoaderFour = LoaderFour;
export default Loading;
