/** @format */

import axios from 'axios';
//const CancelToken = axios.CancelToken;
//const source = CancelToken.source();

const axiosInstance = axios.create({
	baseURL: process.env.API_ENDPOINT,
});

export const api = async (options, ctx = null) => {
	let token = null;

	if (!options.headers) options.headers = {};
	if (!options.method) options.method = 'POST';

	if (ctx && ctx.req) {
		token = (await getTokenFromCookie(ctx.req.headers.cookie)) || null;
		options.headers.Authorization = token;
	} else {
		options.headers.Authorization = ctx;
	}

	//options.url = API_HOST + options.url;
	//options.cancelToken = source.token;

	try {
		const res = await axiosInstance(options);
		return res.data;
	} catch (err) {
		if (
			err?.response?.status === 409 ||
			err?.response?.status === 422 ||
			err?.response?.status === 402
		) {
			return err;
		} else {
			if (axios.isCancel(err)) {
				return {cancelled: true};
			} else {
				throw Error(err);
			}
		}
	}
};

export const getTokenFromCookie = async cookies => {
	let data = null;
	if (typeof cookies === 'string' && cookies.length > 1) {
		data = parseCookie(cookies);
	}
	return data && data.token ? data.token : null;
};

const parseCookie = str =>
	str
		.split(';')
		.map(v => v.split('='))
		.reduce((acc, v) => {
			acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
			return acc;
		}, {});
