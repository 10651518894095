import {api} from '@utils/api';

export const getCategories = async options => {
	let params = {
		url: `/categories`,
		method: 'POST',
	};
	if (options.cookie) {
		params.cookie = options.cookie;
	}
	try {
		return await api(params);
	} catch (error) {
		return {isError: true, error: error.message};
	}
};

export const getCartTotal = async options => {
	let params = {
		url: `/cart/count`,
		method: 'GET',
	};
	try {
		return await api(params, options.session);
	} catch (error) {
		return {isError: true, error: error.message};
	}
};
