/* eslint-disable babel/camelcase */
// https://gist.github.com/avaleriani/fc28b62d9a1a5c6092e7435d4fdc909d
const currency = [
	{
		symbol: '€',
		name: 'Euro',
		symbol_native: '€',
		code: 'EUR',
		emoji: 'EU',
	},
	{
		symbol: '$',
		name: 'US Dollar',
		symbol_native: '$',
		code: 'USD',
		emoji: 'US',
	},
	{
		symbol: 'CA$',
		name: 'Canadian Dollar',
		symbol_native: '$',
		code: 'CAD',
		emoji: 'CA',
	},
	{
		symbol: 'AED',
		name: 'United Arab Emirates Dirham',
		symbol_native: 'د.إ.‏',
		code: 'AED',
		emoji: 'AE',
	},
	{
		symbol: 'Af',
		name: 'Afghan Afghani',
		symbol_native: '؋',
		code: 'AFN',
		emoji: 'AF',
	},
	{
		symbol: 'ALL',
		name: 'Albanian Lek',
		symbol_native: 'Lek',
		code: 'ALL',
		emoji: 'AL',
	},
	{
		symbol: 'AMD',
		name: 'Armenian Dram',
		symbol_native: 'դր.',
		code: 'AMD',
		emoji: 'AM',
	},
	{
		symbol: 'ƒ',
		name: 'Netherlands Antillean guilder',
		symbol_native: 'ƒ',
		code: 'ANG',
		emoji: 'CW',
	},
	{
		symbol: 'Kz',
		name: 'Angolan Kwanza',
		symbol_native: 'Kz',
		code: 'AOA',
		emoji: 'AO',
	},
	{
		symbol: 'AR$',
		name: 'Argentine Peso',
		symbol_native: '$',
		code: 'ARS',
		emoji: 'AR',
	},
	{
		symbol: 'AU$',
		name: 'Australian Dollar',
		symbol_native: '$',
		code: 'AUD',
		emoji: 'AU',
	},
	{
		symbol: 'Afl',
		name: 'Aruban Florin',
		symbol_native: 'Afl',
		code: 'AWG',
		emoji: 'AW',
	},
	{
		symbol: 'man.',
		name: 'Azerbaijani Manat',
		symbol_native: 'ман.',
		code: 'AZN',
		emoji: 'AZ',
	},
	{
		symbol: 'KM',
		name: 'Bosnia-Herzegovina Convertible Mark',
		symbol_native: 'KM',
		code: 'BAM',
		emoji: 'BA',
	},
	{
		symbol: 'Tk',
		name: 'Bangladeshi Taka',
		symbol_native: '৳',
		code: 'BDT',
		emoji: 'BD',
	},
	{
		symbol: 'BGN',
		name: 'Bulgarian Lev',
		symbol_native: 'лв.',
		code: 'BGN',
		emoji: 'BG',
	},
	{
		symbol: 'BD',
		name: 'Bahraini Dinar',
		symbol_native: 'د.ب.‏',
		code: 'BHD',
		emoji: 'BH',
	},
	{
		symbol: 'FBu',
		name: 'Burundian Franc',
		symbol_native: 'FBu',
		code: 'BIF',
		emoji: 'BI',
	},
	{
		symbol: 'BN$',
		name: 'Brunei Dollar',
		symbol_native: '$',
		code: 'BND',
		emoji: 'BN',
	},
	{
		symbol: 'BBD$',
		name: 'Barbadian dollar',
		symbol_native: '$',
		code: 'BBD',
		emoji: 'BB',
	},
	{
		symbol: 'B$',
		name: 'Bahamian Dollar',
		symbol_native: '$',
		code: 'BSD',
		emoji: 'BS',
	},
	{
		symbol: '$',
		name: 'Bermudian Dollar',
		symbol_native: '$',
		code: 'BMD',
		emoji: 'BM',
	},
	{
		symbol: 'Bs',
		name: 'Bolivian Boliviano',
		symbol_native: 'Bs',
		code: 'BOB',
		emoji: 'BO',
	},
	{
		symbol: 'R$',
		name: 'Brazilian Real',
		symbol_native: 'R$',
		code: 'BRL',
		emoji: 'BR',
	},
	{
		symbol: 'Nu.',
		name: 'Bhutanese Ngultrum',
		symbol_native: 'Nu.',
		code: 'BTN',
		emoji: 'BT',
	},
	{
		symbol: 'BWP',
		name: 'Botswanan Pula',
		symbol_native: 'P',
		code: 'BWP',
		emoji: 'BW',
	},
	{
		symbol: 'BYR',
		name: 'Belarusian Ruble',
		symbol_native: 'BYR',
		code: 'BYR',
		emoji: 'BY',
	},
	{
		symbol: 'BZ$',
		name: 'Belize Dollar',
		symbol_native: '$',
		code: 'BZD',
		emoji: 'BZ',
	},
	{
		symbol: 'CDF',
		name: 'Congolese Franc',
		symbol_native: 'FrCD',
		code: 'CDF',
		emoji: 'CD',
	},
	{
		symbol: 'CHF',
		name: 'Swiss Franc',
		symbol_native: 'CHF',
		code: 'CHF',
		emoji: 'CH',
	},
	{
		symbol: 'CL$',
		name: 'Chilean Peso',
		symbol_native: '$',
		code: 'CLP',
		emoji: 'CL',
	},
	{
		symbol: 'CUC$',
		name: 'Cuban convertible peso',
		symbol_native: '$',
		code: 'CUC',
		emoji: 'CU',
	},
	{
		symbol: 'CN¥',
		name: 'Chinese Yuan',
		symbol_native: 'CN¥',
		code: 'CNY',
		emoji: 'CN',
	},
	{
		symbol: 'CO$',
		name: 'Colombian Peso',
		symbol_native: '$',
		code: 'COP',
		emoji: 'CO',
	},
	{
		symbol: '$MN',
		name: 'Cuban peso',
		symbol_native: '$',
		code: 'CUP',
		emoji: 'CU',
	},
	{
		symbol: '₡',
		name: 'Costa Rican Colón',
		symbol_native: '₡',
		code: 'CRC',
		emoji: 'CR',
	},
	{
		symbol: 'CV$',
		name: 'Cape Verdean Escudo',
		symbol_native: 'CV$',
		code: 'CVE',
		emoji: 'CV',
	},
	{
		symbol: 'Kč',
		name: 'Czech Republic Koruna',
		symbol_native: 'Kč',
		code: 'CZK',
		emoji: 'CZ',
	},
	{
		symbol: 'Fdj',
		name: 'Djiboutian Franc',
		symbol_native: 'Fdj',
		code: 'DJF',
		emoji: 'DJ',
	},
	{
		symbol: 'Dkr',
		name: 'Danish Krone',
		symbol_native: 'kr',
		code: 'DKK',
		emoji: 'DK',
	},
	{
		symbol: 'RD$',
		name: 'Dominican Peso',
		symbol_native: 'RD$',
		code: 'DOP',
		emoji: 'DO',
	},
	{
		symbol: 'DA',
		name: 'Algerian Dinar',
		symbol_native: 'د.ج.‏',
		code: 'DZD',
		emoji: 'DZ',
	},
	{
		symbol: 'Ekr',
		name: 'Estonian Kroon',
		symbol_native: 'kr',
		code: 'EEK',
		emoji: 'EE',
	},
	{
		symbol: 'EGP',
		name: 'Egyptian Pound',
		symbol_native: 'ج.م.‏',
		code: 'EGP',
		emoji: 'EG',
	},
	{
		symbol: 'Nfk',
		name: 'Eritrean Nakfa',
		symbol_native: 'Nfk',
		code: 'ERN',
		emoji: 'ER',
	},
	{
		symbol: 'Br',
		name: 'Ethiopian Birr',
		symbol_native: 'Br',
		code: 'ETB',
		emoji: 'ET',
	},
	{
		symbol: '£',
		name: 'Falkland Island Pound',
		symbol_native: '£',
		code: 'FKP',
		emoji: 'FK',
	},
	{
		symbol: 'FJ$',
		name: 'Fijian dollar',
		symbol_native: '$',
		code: 'FJD',
		emoji: 'FJ',
	},
	{
		symbol: '£',
		name: 'British Pound Sterling',
		symbol_native: '£',
		code: 'GBP',
		emoji: 'GB',
	},
	{
		symbol: 'GEL',
		name: 'Georgian Lari',
		symbol_native: 'GEL',
		code: 'GEL',
		emoji: 'GE',
	},
	{
		symbol: 'GH₵',
		name: 'Ghanaian Cedi',
		symbol_native: 'GH₵',
		code: 'GHS',
		emoji: 'GH',
	},
	{
		symbol: '£',
		name: 'Gibraltar Pound',
		symbol_native: '£',
		code: 'GIP',
		emoji: 'GI',
	},
	{
		symbol: 'FG',
		name: 'Guinean Franc',
		symbol_native: 'FG',
		code: 'GNF',
		emoji: 'GN',
	},
	{
		symbol: 'GTQ',
		name: 'Guatemalan Quetzal',
		symbol_native: 'Q',
		code: 'GTQ',
		emoji: 'GT',
	},
	{
		symbol: 'G$',
		name: 'Guyanese dollar',
		symbol_native: '$',
		code: 'GYD',
		emoji: 'GY',
	},
	{
		symbol: 'D',
		name: 'Gambian Dalasi',
		symbol_native: 'D',
		code: 'GMD',
		emoji: 'GM',
	},
	{
		symbol: '£',
		name: 'Guernsey Pound',
		symbol_native: '£',
		code: 'GGP',
		emoji: 'GG',
	},
	{
		symbol: 'HK$',
		name: 'Hong Kong Dollar',
		symbol_native: '$',
		code: 'HKD',
		emoji: 'HK',
	},
	{
		symbol: 'HNL',
		name: 'Honduran Lempira',
		symbol_native: 'L',
		code: 'HNL',
		emoji: 'HN',
	},
	{
		symbol: 'kn',
		name: 'Croatian Kuna',
		symbol_native: 'kn',
		code: 'HRK',
		emoji: 'HR',
	},
	{
		symbol: 'Ft',
		name: 'Hungarian Forint',
		symbol_native: 'Ft',
		code: 'HUF',
		emoji: 'HU',
	},
	{
		symbol: 'G',
		name: 'Haitian Gourde',
		symbol_native: 'G',
		code: 'HTG',
		emoji: 'HT',
	},
	{
		symbol: 'Rp',
		name: 'Indonesian Rupiah',
		symbol_native: 'Rp',
		code: 'IDR',
		emoji: 'ID',
	},
	{
		symbol: '₪',
		name: 'Israeli New Sheqel',
		symbol_native: '₪',
		code: 'ILS',
		emoji: 'IL',
	},
	{
		symbol: '£',
		name: 'Isle of Man Pound',
		symbol_native: '£',
		code: 'IMP',
		emoji: 'IM',
	},
	{
		symbol: 'Rs',
		name: 'Indian Rupee',
		symbol_native: '₹',
		code: 'INR',
		emoji: 'IN',
	},
	{
		symbol: 'IQD',
		name: 'Iraqi Dinar',
		symbol_native: 'د.ع.‏',
		code: 'IQD',
		emoji: 'IQ',
	},
	{
		symbol: 'IRR',
		name: 'Iranian Rial',
		symbol_native: '﷼',
		code: 'IRR',
		emoji: 'IR',
	},
	{
		symbol: 'Ikr',
		name: 'Icelandic Króna',
		symbol_native: 'kr',
		code: 'ISK',
		emoji: 'IS',
	},
	{
		symbol: 'J$',
		name: 'Jamaican Dollar',
		symbol_native: '$',
		code: 'JMD',
		emoji: 'JM',
	},
	{
		symbol: 'JD',
		name: 'Jordanian Dinar',
		symbol_native: 'د.أ.‏',
		code: 'JOD',
		emoji: 'JO',
	},
	{
		symbol: '¥',
		name: 'Japanese Yen',
		symbol_native: '￥',
		code: 'JPY',
		emoji: 'JP',
	},
	{
		symbol: 'с',
		name: 'Kyrgyzstani som',
		symbol_native: 'с',
		code: 'KGS',
		emoji: 'KG',
	},
	{
		symbol: 'KHR',
		name: 'Cambodian Riel',
		symbol_native: '៛',
		code: 'KHR',
		emoji: 'KH',
	},
	{
		symbol: 'CF',
		name: 'Comorian Franc',
		symbol_native: 'FC',
		code: 'KMF',
		emoji: 'KM',
	},
	{
		symbol: '₩',
		name: 'North Korean Won',
		symbol_native: '₩',
		code: 'KPW',
		emoji: 'KP',
	},
	{
		symbol: '₩',
		name: 'South Korean Won',
		symbol_native: '₩',
		code: 'KRW',
		emoji: 'KR',
	},
	{
		symbol: 'KD',
		name: 'Kuwaiti Dinar',
		symbol_native: 'د.ك.‏',
		code: 'KWD',
		emoji: 'KW',
	},
	{
		symbol: '$',
		name: 'Cayman Islands dollar',
		symbol_native: '$‏',
		code: 'KYD',
		emoji: 'KY',
	},
	{
		symbol: 'KZT',
		name: 'Kazakhstani Tenge',
		symbol_native: 'тңг.',
		code: 'KZT',
		emoji: 'KZ',
	},
	{
		symbol: 'Ksh',
		name: 'Kenyan Shilling',
		symbol_native: 'Ksh',
		code: 'KES',
		emoji: 'KE',
	},
	{
		symbol: '₭',
		name: 'Lao kip',
		symbol_native: '₭‏',
		code: 'LAK',
		emoji: 'LA',
	},
	{
		symbol: 'LB£',
		name: 'Lebanese Pound',
		symbol_native: 'ل.ل.‏',
		code: 'LBP',
		emoji: 'LB',
	},
	{
		symbol: 'SLRs',
		name: 'Sri Lankan Rupee',
		symbol_native: 'SL Re',
		code: 'LKR',
		emoji: 'LK',
	},
	{
		symbol: '$',
		name: 'Liberian Dollar',
		symbol_native: '$',
		code: 'LRD',
		emoji: 'LR',
	},
	{
		symbol: 'Lt',
		name: 'Lithuanian Litas',
		symbol_native: 'Lt',
		code: 'LTL',
		emoji: 'LT',
	},
	{
		symbol: 'L',
		name: 'Basotho Loti',
		symbol_native: 'L',
		code: 'LSL',
		emoji: 'LS',
	},
	{
		symbol: 'Ls',
		name: 'Latvian Lats',
		symbol_native: 'Ls',
		code: 'LVL',
		emoji: 'LV',
	},
	{
		symbol: 'LD',
		name: 'Libyan Dinar',
		symbol_native: 'د.ل.‏',
		code: 'LYD',
		emoji: 'LY',
	},
	{
		symbol: 'MAD',
		name: 'Moroccan Dirham',
		symbol_native: 'د.م.‏',
		code: 'MAD',
		emoji: 'MA',
	},
	{
		symbol: 'MDL',
		name: 'Moldovan Leu',
		symbol_native: 'MDL',
		code: 'MDL',
		emoji: 'MD',
	},
	{
		symbol: 'MGA',
		name: 'Malagasy Ariary',
		symbol_native: 'MGA',
		code: 'MGA',
		emoji: 'MG',
	},
	{
		symbol: 'MKD',
		name: 'Macedonian Denar',
		symbol_native: 'MKD',
		code: 'MKD',
		emoji: 'MK',
	},
	{
		symbol: 'MMK',
		name: 'Myanma Kyat',
		symbol_native: 'K',
		code: 'MMK',
		emoji: 'MM',
	},
	{
		symbol: 'MOP$',
		name: 'Macanese Pataca',
		symbol_native: 'MOP$',
		code: 'MOP',
		emoji: 'MO',
	},
	{
		symbol: 'MURs',
		name: 'Mauritian Rupee',
		symbol_native: 'MURs',
		code: 'MUR',
		emoji: 'MU',
	},
	{
		symbol: 'MK',
		name: 'Malawian Kwacha',
		symbol_native: 'MK',
		code: 'MWK',
		emoji: 'MW',
	},
	{
		symbol: 'MVR',
		name: 'Maldivian Rufiyaa',
		symbol_native: 'ރ',
		code: 'MVR',
		emoji: 'MV',
	},
	{
		symbol: 'MX$',
		name: 'Mexican Peso',
		symbol_native: '$',
		code: 'MXN',
		emoji: 'MX',
	},
	{
		symbol: 'RM',
		name: 'Malaysian Ringgit',
		symbol_native: 'RM',
		code: 'MYR',
		emoji: 'MY',
	},
	{
		symbol: 'MTn',
		name: 'Mozambican Metical',
		symbol_native: 'MTn',
		code: 'MZN',
		emoji: 'MZ',
	},
	{
		symbol: 'N$',
		name: 'Namibian Dollar',
		symbol_native: 'N$',
		code: 'NAD',
		emoji: 'NA',
	},
	{
		symbol: '₦',
		name: 'Nigerian Naira',
		symbol_native: '₦',
		code: 'NGN',
		emoji: 'NG',
	},
	{
		symbol: 'C$',
		name: 'Nicaraguan Córdoba',
		symbol_native: 'C$',
		code: 'NIO',
		emoji: 'NI',
	},
	{
		symbol: 'Nkr',
		name: 'Norwegian Krone',
		symbol_native: 'kr',
		code: 'NOK',
		emoji: 'NP',
	},
	{
		symbol: 'NPRs',
		name: 'Nepalese Rupee',
		symbol_native: 'नेरू',
		code: 'NPR',
		emoji: 'NP',
	},
	{
		symbol: 'NZ$',
		name: 'New Zealand Dollar',
		symbol_native: '$',
		code: 'NZD',
		emoji: 'NZ',
	},
	{
		symbol: 'OMR',
		name: 'Omani Rial',
		symbol_native: 'ر.ع.‏',
		code: 'OMR',
		emoji: 'OM',
	},
	{
		symbol: 'B/.',
		name: 'Panamanian Balboa',
		symbol_native: 'B/.',
		code: 'PAB',
		emoji: 'PA',
	},
	{
		symbol: 'S/.',
		name: 'Peruvian Nuevo Sol',
		symbol_native: 'S/.',
		code: 'PEN',
		emoji: 'PE',
	},
	{
		symbol: '₱',
		name: 'Philippine Peso',
		symbol_native: '₱',
		code: 'PHP',
		emoji: 'PH',
	},
	{
		symbol: 'PKRs',
		name: 'Pakistani Rupee',
		symbol_native: '₨',
		code: 'PKR',
		emoji: 'PK',
	},
	{
		symbol: 'K',
		name: 'Papua New Guinean Kina',
		symbol_native: 'K',
		code: 'PGK',
		emoji: 'PG',
	},
	{
		symbol: 'zł',
		name: 'Polish Zloty',
		symbol_native: 'zł',
		code: 'PLN',
		emoji: 'PL',
	},
	{
		symbol: '₲',
		name: 'Paraguayan Guarani',
		symbol_native: '₲',
		code: 'PYG',
		emoji: 'PY',
	},
	{
		symbol: 'QR',
		name: 'Qatari Rial',
		symbol_native: 'ر.ق.‏',
		code: 'QAR',
		emoji: 'QA',
	},
	{
		symbol: 'RON',
		name: 'Romanian Leu',
		symbol_native: 'RON',
		code: 'RON',
		emoji: 'RO',
	},
	{
		symbol: 'din.',
		name: 'Serbian Dinar',
		symbol_native: 'дин.',
		code: 'RSD',
		emoji: 'RS',
	},
	{
		symbol: 'RUB',
		name: 'Russian Ruble',
		symbol_native: 'руб.',
		code: 'RUB',
		emoji: 'RU',
	},
	{
		symbol: 'RWF',
		name: 'Rwandan Franc',
		symbol_native: 'FR',
		code: 'RWF',
		emoji: 'RW',
	},
	{
		symbol: 'SR',
		name: 'Saudi Riyal',
		symbol_native: 'ر.س.‏',
		code: 'SAR',
		emoji: 'SA',
	},
	{
		symbol: '₨',
		name: 'Seychellois Rupee',
		symbol_native: '₨',
		code: 'SCR',
		emoji: 'SC',
	},
	{
		symbol: 'WS$',
		name: 'Samoan Tala',
		symbol_native: '$',
		code: 'WST',
		emoji: 'WS',
	},
	{
		symbol: '$',
		name: 'Solomon Islander Dollar',
		symbol_native: '$',
		code: 'SBD',
		emoji: 'SB',
	},
	{
		symbol: '₡',
		name: 'Salvadoran Colon',
		symbol_native: '₡',
		code: 'SVC',
		emoji: 'SV',
	},
	{
		symbol: 'SDG',
		name: 'Sudanese Pound',
		symbol_native: 'SDG',
		code: 'SDG',
		emoji: 'SD',
	},
	{
		symbol: 'Sr$',
		name: 'Surinamese Dollar',
		symbol_native: '$',
		code: 'SRD',
		emoji: 'SR',
	},
	{
		symbol: 'Skr',
		name: 'Swedish Krona',
		symbol_native: 'kr',
		code: 'SEK',
		emoji: 'SE',
	},
	{
		symbol: 'S$',
		name: 'Singapore Dollar',
		symbol_native: '$',
		code: 'SGD',
		emoji: 'SG',
	},
	{
		symbol: 'Le',
		name: 'Sierra Leonean Leone',
		symbol_native: 'Le',
		code: 'SLL',
		emoji: 'SL',
	},
	{
		symbol: 'Ssh',
		name: 'Somali Shilling',
		symbol_native: 'Ssh',
		code: 'SOS',
		emoji: 'SO',
	},
	{
		symbol: '£',
		name: 'South Sudanese pound',
		symbol_native: '£',
		code: 'SSP',
		emoji: 'SS',
	},
	{
		symbol: 'Db',
		name: 'Sao Tomean Dobra',
		symbol_native: 'Db',
		code: 'STN',
		emoji: 'ST',
	},
	{
		symbol: '£',
		name: 'Saint Helenian Pound',
		symbol_native: '£',
		code: 'SHP',
		emoji: 'SH',
	},
	{
		symbol: 'SY£',
		name: 'Syrian Pound',
		symbol_native: 'ل.س.‏',
		code: 'SYP',
		emoji: 'SY',
	},
	{
		symbol: 'L',
		name: 'Swazi Lilangeni',
		symbol_native: 'L‏',
		code: 'SZL',
		emoji: 'SZ',
	},
	{
		symbol: '฿',
		name: 'Thai Baht',
		symbol_native: '฿',
		code: 'THB',
		emoji: 'TH',
	},
	{
		symbol: 'ЅМ',
		name: 'Tajikistani Somoni',
		symbol_native: 'ЅМ',
		code: 'TJS',
		emoji: 'TJ',
	},
	{
		symbol: 'DT',
		name: 'Tunisian Dinar',
		symbol_native: 'د.ت.‏',
		code: 'TND',
		emoji: 'TN',
	},
	{
		symbol: 'T$',
		name: 'Tongan Paʻanga',
		symbol_native: 'T$',
		code: 'TOP',
		emoji: 'TO',
	},
	{
		symbol: 'TL',
		name: 'Turkish Lira',
		symbol_native: 'TL',
		code: 'TRY',
		emoji: 'TR',
	},
	{
		symbol: 'm',
		name: 'Turkmenistani Manat',
		symbol_native: '',
		code: 'TMT',
		emoji: 'TM',
	},
	{
		symbol: 'TT$',
		name: 'Trinidad and Tobago Dollar',
		symbol_native: '$',
		code: 'TTD',
		emoji: 'TT',
	},
	{
		symbol: 'NT$',
		name: 'New Taiwan Dollar',
		symbol_native: 'NT$',
		code: 'TWD',
		emoji: 'TW',
	},
	{
		symbol: '£',
		name: 'Jersey pound',
		symbol_native: '£',
		code: 'JEP',
		emoji: 'JE',
	},
	{
		symbol: 'TSh',
		name: 'Tanzanian Shilling',
		symbol_native: 'TSh',
		code: 'TZS',
		emoji: 'TZ',
	},
	{
		symbol: '₴',
		name: 'Ukrainian Hryvnia',
		symbol_native: '₴',
		code: 'UAH',
		emoji: 'UA',
	},
	{
		symbol: 'USh',
		name: 'Ugandan Shilling',
		symbol_native: 'USh',
		code: 'UGX',
		emoji: 'UG',
	},
	{
		symbol: 'UM',
		name: 'Mauritanian Ouguiya',
		symbol_native: 'أوقية‎',
		code: 'MRU',
		emoji: 'MR',
	},
	{
		symbol: '₮',
		name: 'Mongolian Tughrik',
		symbol_native: '₮',
		code: 'MNT',
		emoji: 'MN',
	},
	{
		symbol: '$U',
		name: 'Uruguayan Peso',
		symbol_native: '$',
		code: 'UYU',
		emoji: 'UY',
	},
	{
		symbol: 'UZS',
		name: 'Uzbekistan Som',
		symbol_native: 'UZS',
		code: 'UZS',
		emoji: 'UZ',
	},
	{
		symbol: 'Bs.F.',
		name: 'Venezuelan Bolívar',
		symbol_native: 'Bs.F.',
		code: 'VEF',
		emoji: 'VE',
	},
	{
		symbol: '₫',
		name: 'Vietnamese Dong',
		symbol_native: '₫',
		code: 'VND',
		emoji: 'VN',
	},
	{
		symbol: 'Vt',
		name: 'Ni-Vanuatu Vatu',
		symbol_native: 'Vt',
		code: 'VUV',
		emoji: 'VU',
	},
	{
		symbol: 'FCFA',
		name: 'CFA Franc BEAC',
		symbol_native: 'FCFA',
		code: 'XAF',
		emoji: 'CF',
	},
	{
		symbol: '$',
		name: 'East Caribbean Dollar',
		symbol_native: '$',
		code: 'XCD',
		emoji: 'AG',
	},
	{
		symbol: 'CFA',
		name: 'CFA Franc BCEAO',
		symbol_native: 'CFA',
		code: 'XOF',
		emoji: 'CI',
	},
	{
		symbol: 'Fr',
		name: 'CFP franc',
		symbol_native: 'Fr',
		code: 'XPF',
		emoji: 'PF',
	},
	{
		symbol: 'YR',
		name: 'Yemeni Rial',
		symbol_native: 'ر.ي.‏',
		code: 'YER',
		emoji: 'YE',
	},
	{
		symbol: 'R',
		name: 'South African Rand',
		symbol_native: 'R',
		code: 'ZAR',
		emoji: 'ZA',
	},
	{
		symbol: 'ZK',
		name: 'Zambian Kwacha',
		symbol_native: 'ZK',
		code: 'ZMK',
		emoji: 'ZM',
	},
	{
		symbol: 'Z$',
		name: 'Zimbabwean dollar',
		symbol_native: '$',
		code: 'ZWL',
		emoji: 'ZW',
	},
];

export const getCurrency = currencyCode => {
	let cur = currency.find(
		cur => cur.code.toLowerCase() === currencyCode.toLowerCase(),
	);
	if (cur) return cur;
};
export const getCurrencyByIsoCode = isoCode => {
	let cur = currency.find(
		cur => cur.emoji.toLowerCase() === isoCode.toLowerCase(),
	);
	if (cur) return cur;
};
