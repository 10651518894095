import dynamic from 'next/dynamic';
import {useEffect, useState} from 'react';

import {useApp} from '@context/AppProvider';
import Loading from '@components/Loading';
import {CategoryProvider} from '@context/CategoryProvider';
import {GoodCauseProvider} from '@context/GoodCauseProvider';
import {CartProvider} from '@context/CartProvider';
import {AuthGuard} from '@middlewares/auth';

const Header = dynamic(() => import('@components/common/header/index'));
const Footer = dynamic(() =>
	import('@components/common/footer/Footer.component'),
);
const BannerSlider = dynamic(() => import('@components/bannerSlider/index'));

function Layout({children, ...rest}) {
	const {
		idb: {session},
	} = useApp();
	const [container, setContainer] = useState(false);

	useEffect(() => {
		if (session) setContainer(true);
	}, [session]);
	return (
		<>
			{!container && <Loading />}
			<CategoryProvider data={rest.categories}>
				<GoodCauseProvider>
					<CartProvider>
						<Header
							hideMenu={rest.hideMenu}
							hideMiddleHeader={rest.hideMiddleHeader}
							hideGoodCause={rest.hideGoodCause || false}
							hideSearch={rest.hideSearch || false}
						/>
						<BannerSlider types={'header'} />
						{rest.requireAuth ? (
							<AuthGuard>
								<div
									className={`${
										!rest.hideFooter
											? 'viewPortWithFooter'
											: 'viewPortWithOutFooter'
									}`}
								>
									{children}
								</div>
							</AuthGuard>
						) : (
							<div
								className={`${
									!rest.hideFooter
										? 'viewPortWithFooter'
										: 'viewPortWithOutFooter'
								}`}
							>
								{children}
							</div>
						)}
						{!rest.hideFooter && <Footer />}
					</CartProvider>
				</GoodCauseProvider>
			</CategoryProvider>
		</>
	);
}

export default Layout;
