import {formatInTimeZone} from 'date-fns-tz';
import {addDays} from 'date-fns';

import {api} from '@utils/api';

export const getStateName = stateCode => {
	const states = {
		NSW: 'New South Wales',
		OT: 'New South Wales',
		NT: 'Nothern Territory',
		QLD: 'Queensland',
		VIC: 'Victoria',
		WA: 'Western Australia',
		TAS: 'Tasmania',
		SA: 'South Australia',
		ACT: 'Australian Capital Territory',
	};
	return states[stateCode];
};
export const getStateCode = stateName => {
	const states = {
		'New South Wales': 'NSW',
		'Nothern Territory': 'NT',
		Queensland: 'QLD',
		Victoria: 'VIC',
		'Western Australia': 'WA',
		Tasmania: 'TAS',
		'South Australia': 'SA',
		'Australian Capital Territory': 'ACT',
	};
	return states[stateName];
};

export const getStateShortName = name => {
	const states = {
		'New South Wales': 'NSW',
		// eslint-disable-next-line no-dupe-keys
		'New South Wales': 'OT',
		// eslint-disable-next-line no-dupe-keys
		'New South Wales': 'NT',
		Queensland: 'QLD',
		// eslint-disable-next-line no-dupe-keys
		'New South Wales': 'VIC',
		// eslint-disable-next-line no-dupe-keys
		'New South Wales': 'WA',
		// eslint-disable-next-line no-dupe-keys
		'New South Wales': 'TAS',
		// eslint-disable-next-line no-dupe-keys
		'New South Wales': 'SA',
		// eslint-disable-next-line no-dupe-keys
		'New South Wales': 'ACT',
	};
	return states[name];
};

export const formatPrice = amount => {
	return amount ? parseFloat(amount).toFixed(2) : 0;
};

export const standardNumberFormat = (number, decimal = 2) => {
	return new Intl.NumberFormat('en-AU', {
		minimumFractionDigits: decimal,
		maximumFractionDigits: decimal,
	}).format(number);
};

export const calcualteProductGoodCause = (
	price,
	btFee,
	goodCausePer,
	stripeFee,
	retailerData = null,
) => {
	const btfee = btFee ? parseFloat(btFee / 100) : 0.1;
	const goodCauseStripe = (
		Number(price) * Number(stripeFee / 100) +
		0.3
	).toFixed(2);
	const detectStripeFee = parseFloat((goodCauseStripe * 10) / 11).toFixed(2);
	const commission = parseFloat(price * btfee).toFixed(2);
	const stripeExclCommission = parseFloat(
		Math.abs(commission) - detectStripeFee,
	).toFixed(2);
	let gcAmount = parseFloat(
		Math.abs(stripeExclCommission) * Number(goodCausePer / 100),
	);
	let retailerGcAmount = null;
	let retailerGoodName = null;
	let retailerCharityId = null;
	if (retailerData && retailerData.charity) {
		retailerGcAmount =
			(price * retailerData.retailerDonation.donation_value) / 100;

		retailerGoodName =
			retailerData &&
			retailerData.charity &&
			retailerData.charity.registeredCharityName &&
			retailerData.charity.registeredCharityName;

		retailerCharityId =
			retailerData && retailerData.charity && retailerData.charity._id;
	}
	return {
		gcAmount: Math.round(Number(gcAmount) * 100) / 100,
		shop_name: retailerData && retailerData.shop_name,
		retailerGoodName: retailerGoodName,
		retailerCharityId: retailerCharityId,
		retailerGcAmount:
			retailerGcAmount && retailerGcAmount !== 0
				? Math.round(Number(retailerGcAmount) * 100) / 100
				: null,
	};
};

export const searchAddressViaAddressFinder = (searchValue, country) => {
	return api({
		url: `/searchAddress?searchVal=${searchValue.toString()}&&country=${country}`,
		method: 'get',
	});
};

export const searchMetadataOfAddress = (country, id) => {
	return api({
		url: `/searchAddressMetaData?searchId=${id}&&country=${
			country === 'Australia' ? 'au' : 'nz'
		}`,
		method: 'get',
	});
};

export const splitDataOfAddress = (text, country) => {
	const commaSplit = text.split(',');
	const obj = {
		address: '',
		address1: '',
		postcode: '',
		Suburb: '',
		state: '',
	};
	if (commaSplit.length > 2) {
		obj.address1 = commaSplit[commaSplit.length - 2];
		obj.address = '';
		for (let i = 0; i < commaSplit.length - 2; i++) {
			obj.address += commaSplit[i] + ',';
		}
		obj.address = obj.address.slice(0, obj.address.length - 1);
	} else {
		obj.address1 = '';
		obj.address = commaSplit[0];
	}

	const spaceSplit = commaSplit[commaSplit.length - 1].split(' ');
	obj.postcode = spaceSplit[spaceSplit.length - 1];
	obj.Suburb = '';
	for (let j = 1; j < spaceSplit.length - 2; j++) {
		obj.Suburb += spaceSplit[j] + ' ';
	}
	obj.state = spaceSplit[spaceSplit.length - 2];
	if (country !== 'Australia') {
		obj.Suburb = obj.state;
		obj.state = '';
	}
	return obj;
};

export const excludeGSTPrice = amount => {
	// const location = origin || 'au';
	// if (location !== 'au') {
	// 	return amount / 1.1;
	// } else {
	// 	return amount;
	// }
	return amount;
};

export const convertWeight = (unit, weight) => {
	let wg = weight;
	switch (unit) {
		case 'lb':
			wg = Number(weight) / 2.205;
			break;
		case 'oz':
			wg = Number(weight) / 35.274;
			break;
		case 'g':
			wg = Number(weight) / 1000;
			break;
		default:
			wg = Number(weight);
	}
	return Number(wg).toFixed(2);
};

export const round = num => {
	const m = Number((Math.abs(num) * 100).toPrecision(15));
	return (Math.round(m) / 100) * Math.sign(num);
};

export const preciseRound = (num, dec) => {
	if (typeof num !== 'number' || typeof dec !== 'number') return false;
	const numSign = num >= 0 ? 1 : -1;

	return (
		Math.round(num * Math.pow(10, dec) + numSign * 0.0001) / Math.pow(10, dec)
	).toFixed(dec);
};

export const calculateReferrerFee = (
	btfee,
	orderTotal,
	shippingFee,
	userCountry = '',
) => {
	const btFee = Math.round(parseFloat(btfee) * 100) / 100;
	const orderValue = orderTotal;
	orderTotal = Number(orderValue) - Number(shippingFee);
	orderTotal = Number(orderTotal);
	const deductAmount = userCountry === 'New Zealand' ? 0.029 : 0.0175;
	let totalAmountOfShopper = parseFloat(orderTotal + shippingFee);
	let totalStripeFee = totalAmountOfShopper * deductAmount + 0.3;
	let stripeExclCommision = parseFloat(totalStripeFee - totalStripeFee / 100);
	return parseFloat((btFee - stripeExclCommision) * 0.15);
};

export const calculateTransactionFee = (feePercentage, amount) => {
	const amountFee = parseFloat(amount * (feePercentage / 100));
	return Math.round(amountFee * 100) / 100;
};

export const calculateCharityAmount = (
	btfee,
	retailersCount = 1,
	orderTotal,
	voucherAmount = 0,
	shippingFee,
	auStripeFee = 1.75,
	intStripeFee = 2.9,
	gcPercentage = 25,
	// eslint-disable-next-line no-unused-vars
	userCountry = '',
	cardCountry = 'AU',
) => {
	const btFee = Math.round(parseFloat(btfee) * 100) / 100;
	const orderValue = orderTotal;
	orderTotal = Number(orderValue) - Number(shippingFee);
	orderTotal = Number(orderTotal);
	let stripeFee = 0;
	if (cardCountry.toLowerCase() === 'au') {
		stripeFee = Number(auStripeFee);
	} else {
		stripeFee = Number(intStripeFee);
	}
	const deductAmount = parseFloat(Number(stripeFee) / 100);

	let totalAmountToShopper =
		Math.round(parseFloat(Number(orderTotal) + Number(shippingFee)) * 100) /
		100;
	let vocherAmountDistribute = Number(voucherAmount) / Number(retailersCount);
	let stripeDistribute = Number(0.3);
	let totalStripeFee = round(
		totalAmountToShopper * deductAmount + stripeDistribute,
	).toFixed(2);

	if (voucherAmount) {
		totalStripeFee = round(
			(totalAmountToShopper - vocherAmountDistribute) * deductAmount +
				stripeDistribute,
		).toFixed(2);
	}

	const gstFee = parseFloat((totalStripeFee * 10) / 11).toFixed(2);
	const btCommission = round(parseFloat(btFee - Number(gstFee)));

	let gcAmount = parseFloat(
		Math.abs(btCommission) * Number(gcPercentage / 100),
	);
	// if (retailerDonation) {
	// 	gcAmount += (totalAmountToShopper * retailerDonation) / 100;
	// }

	return Number(gcAmount);
};

export const formatGoodCauseValue = amount => {
	const amt = amount ? amount : 0;
	const m = Number((Math.abs(amt) * 100).toPrecision(15));
	return ((Math.round(m) / 100) * Math.sign(amt)).toFixed(2);
};

export const getRouteUrl = path => {
	let url = path.split('/');
	url = url.filter(item => item);
	if (url && url[0] === '#!') {
		url.shift();
	}
	let requesturl = url ? url[0] : [];
	if (url && url.length) {
		if (url[0] === 'products' && url[1]) {
			requesturl = 'category';
		} else if (url[0] === 'drop-auctions' && url[1] === 'all') {
			requesturl = 'drop-all-auction';
		} else if (url[0] === 'product' && url[1] === 'store') {
			requesturl = 'store';
		}
	} else if (!url[0]) {
		requesturl = 'home-page';
	}

	return requesturl;
};

export const listToTree = list => {
	list = JSON.parse(JSON.stringify(list));
	let map = {},
		node,
		roots = [],
		i;

	for (i = 0; i < list.length; i += 1) {
		map[list[i]._id] = i; // initialize the map
		list[i].children = []; // initialize the children
	}
	for (i = 0; i < list.length; i += 1) {
		node = list[i];
		if (node.parent_id !== null) {
			if (list[map[node.parent_id]])
				list[map[node.parent_id]].children.push(node);
		} else {
			roots.push(node);
		}
	}
	// root sort
	// if (filter)
	// 	roots.sort(function (a, b) {
	// 		if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
	// 			return -1;
	// 		}
	// 		if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
	// 			return 1;
	// 		}
	// 		return 0;
	// 	});
	roots = sortChildren(roots);
	return roots;
};

export const sortChildren = categoryData => {
	for (let i = 0; i < categoryData.length; i++) {
		// parent sort
		categoryData[i].children.sort(function (a, b) {
			if (a.sort_order < b.sort_order) {
				return -1;
			}
			if (a.sort_order > b.sort_order) {
				return 1;
			}
			return 0;
		});
		//  childrend sort
		for (let j = 0; j < categoryData[i].children.length; j++) {
			categoryData[i].children[j].children.sort(function (a, b) {
				if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
					return -1;
				}
				if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
					return 1;
				}
				return 0;
			});
			for (let k = 0; k < categoryData[i].children[j].children.length; k++) {
				categoryData[i].children[j].children[k].children.sort(function (a, b) {
					if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
						return -1;
					}
					if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
						return 1;
					}
					return 0;
				});
			}
		}
	}
	return categoryData;
};
export const getFormattedShippingOptionName = name => {
	const states = {
		Instore: 'Pickup In Store',
		ExpressDelivery: 'Express Delivery',
		Standard: 'Standard Delivery - Retailer Managed',
		StandardDeliverySendle: 'Standard Delivery - Sendle',
		InternationalDelivery: 'International Delivery',
		International: 'International Delivery - Retailer Managed',
		SameDayShipping: 'Same Day Shipping',
		AustraliaWideShipping: 'Australia Wide Shipping',
	};
	return states[name];
};

export const capitalize = s => {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const categorySlug = async (url, path) => {
	let {slug} = url;
	let requesturl = await getCategoryPage(path);
	if (requesturl) {
		switch (requesturl) {
			case 'products':
				if (slug && slug.length === 1) {
					slug = slug[0];
					break;
				}
				break;
			case 'brand':
				if (slug.length === 2) {
					slug = slug[1];
					break;
				}
				break;
			case 'stores':
				if (slug.length === 2) {
					slug = slug[1];
					break;
				}
				break;
			default:
				slug = '';
		}

		return slug;
	} else {
		return (slug = '');
	}
};

const getCategoryPage = path => {
	let pageUrl = path.split('/');
	pageUrl = pageUrl.filter(item => item);
	if (pageUrl && pageUrl[0] === '#!') {
		pageUrl.shift();
	}
	let requesturl = pageUrl ? pageUrl[0] : [];
	if (pageUrl && pageUrl.length) {
		if (pageUrl[0] === 'products' && pageUrl[1]) {
			requesturl = 'products';
		} else if (pageUrl[0] === 'brand' && pageUrl[1]) {
			requesturl = 'brand';
		} else if (pageUrl[0] === 'stores' && pageUrl[1]) {
			requesturl = 'stores';
		}
	}
	return requesturl;
};
export const gcTotal = (cart, settings) => {
	let rp = {},
		retailerGcTotal = {},
		amount = 0,
		transactionFee = {},
		referrerAmt = {};

	if (cart?.products) {
		for (let i = 0; i < cart.products.length; i++) {
			const p = cart.products[i];
			if (rp[p.retailer_id]) {
				rp[p.retailer_id].push(p);
			} else {
				rp[p.retailer_id] = [p];
			}
		}
	}

	for (let i = 0; i < Object.keys(rp).length; i++) {
		const retailer = rp[Object.keys(rp)[i]];
		let btFee = 0,
			productTotal = 0,
			country = '';

		for (let r = 0; r < retailer.length; r++) {
			const p = retailer[r];
			let tFee = settings.default_bt_fee || 10;
			if (
				p.product_data.retailer_products.transaction_charge_option &&
				p.product_data.retailer_products.transaction_charge_percentage
			) {
				tFee = +p.product_data.retailer_products.transaction_charge_percentage;
			} else if (p.product_data.retailer.transactionChargePercentage) {
				tFee = +p.product_data.retailer.transactionChargePercentage;
			}
			productTotal += p.total_amount;
			btFee += Math.round(p.total_amount * (tFee / 100) * 100) / 100;
		}

		const extraFee = parseFloat(btFee * 0.1);
		let totalFee = parseFloat(btFee + Math.round(extraFee * 100) / 100);
		totalFee = Math.round(totalFee * 100) / 100;
		transactionFee[retailer[0].retailer_id] = totalFee;

		const retailerShipping = cart.selectedShippings
			? cart.selectedShippings.find(
					i => i.retailer_id === retailer[0].retailer_id,
			  )
			: null;
		const retailerShippingTotal = retailerShipping ? retailerShipping.value : 0;
		const totalIncludingShipping = productTotal + retailerShippingTotal;

		let totalGc = calculateCharityAmount(
			btFee,
			Object.keys(rp).length,
			totalIncludingShipping,
			0,
			retailerShippingTotal,
			settings.australia_stripe_fee,
			settings.international_stripe_fee,
			settings.good_cause_percentage,
			country,
		);

		retailerGcTotal[retailer[0].retailer_id] = totalGc;
		referrerAmt[retailer[0].retailer_id] = 0;
		amount += totalGc;
	}

	return {
		amount: Math.round(amount * 100) / 100,
		retailerGcTotal,
		transactionFee,
		referrerAmt,
	};
};

export const cartData = data => {
	const res = {
		items: [],
		address: {},
		shipping: [],
		goodCause: {},
		promo: {},
		total: {},
		tax: {},
	};
	if (!data.cart) return res;
	res.items = data.cart.products;
	res.address = data.cart.selectedAddress || {};
	res.shipping = data.cart.selectedShippings || [];
	res.promo = data.cart.voucher || {};
	res.total = {
		voucher: res.promo,
		subTotal: data.cart.subTotal,
		totalShipping: data.cart.totalShipping,
		totalGst: data.cart.totalGst,
		totalGstShipping: data.cart.totalGstShipping,
		tax: data.cart.taxRate,
	};

	return res;
};
export const goodCauseShowRetailerWise = data => {
	let goodRetailerWise = Object.values(data).reduce((rt, val) => {
		if (val['retailerCharityId']) {
			(rt[val['retailerCharityId']] = rt[val['retailerCharityId']] || []).push(
				val,
			);
		}

		return rt;
	}, {});
	return Object.values(goodRetailerWise);
};
export const customerAddresses = async session => {
	const {data} = await api(
		{
			method: 'GET',
			url: `/user/address`,
		},
		session,
	);
	console.log({data});
	return data;
};

export const convertToAud = (price, currencyData, currency) => {
	let currentCurrency = currencyData.find(
		cur => cur.currency.toLowerCase() === currency,
	);
	return Number(price) * currentCurrency?.converted_rate;
};

export const convertCurrencyAdjust = (price, currencyData) => {
	if (currencyData?.currency?.toLowerCase() === 'aud') return Number(price);

	var audConvertedRate = currencyData.increased_rate
		? (
				currencyData.live_rate +
				(currencyData.increased_rate * currencyData.live_rate) / 100
		  ).toFixed(5)
		: (1 * currencyData.live_rate).toFixed(5);
	// (currencyData?.live_rate / audConvertPrice?.live_rate) * price;
	var convertedPrice = audConvertedRate * price;
	if (currencyData && convertedPrice > 0) {
		// convertedPrice =
		// 	convertedPrice + (convertedPrice * currencyData.increased_rate) / 100;
		if (currencyData.round_off) {
			if (currencyData.round_off === 1) {
				convertedPrice = (Math.round(1 * convertedPrice) / 1).toFixed(2);
			}
			if (currencyData.round_off === 5) {
				convertedPrice = (Math.ceil(20 * convertedPrice) / 20).toFixed(2);
			}
			if (currencyData.round_off === 10) {
				convertedPrice = (Math.round(10 * convertedPrice) / 10).toFixed(2);
			}
			if (currencyData.round_off === 25) {
				convertedPrice = (Math.ceil(4 * convertedPrice) / 4).toFixed(2);
			}
			if (currencyData.round_off === 50) {
				convertedPrice = (Math.round(2 * convertedPrice) / 2).toFixed(2);
			}
		} else {
			convertedPrice = convertedPrice.toFixed(2);
		}
	}
	return Number(convertedPrice);
};

export const convertCurrency = (price, currencyData) => {
	var convertedPrice = currencyData ? price : price;
	if (currencyData && convertedPrice > 0) {
		convertedPrice = currencyData.increased_rate
			? convertedPrice *
			  (currencyData.converted_rate +
					(currencyData.converted_rate * currencyData.increased_rate) / 100)
			: currencyData.converted_rate * convertedPrice;
		if (currencyData.round_off) {
			if (currencyData.round_off === 1) {
				convertedPrice = (Math.round(1 * convertedPrice) / 1).toFixed(2);
			}
			if (currencyData.round_off === 5) {
				convertedPrice = (Math.ceil(20 * convertedPrice) / 20).toFixed(2);
			}
			if (currencyData.round_off === 10) {
				convertedPrice = (Math.round(10 * convertedPrice) / 10).toFixed(2);
			}
			if (currencyData.round_off === 25) {
				convertedPrice = (Math.ceil(4 * convertedPrice) / 4).toFixed(2);
			}
			if (currencyData.round_off === 50) {
				convertedPrice = (Math.round(2 * convertedPrice) / 2).toFixed(2);
			}
		} else {
			convertedPrice = convertedPrice.toFixed(2);
		}
	}
	return Number(convertedPrice);
};

export const getCreatedTime = time => {
	return formatInTimeZone(time, 'Australia/Sydney', 'dd/MM/yyyy');
};

export const getFormatDateTime = time => {
	return formatInTimeZone(time, 'Australia/Sydney', 'MMMM dd yyyy hh:mm a');
};
export const returnDate = (date, days) => {
	const dates = new Date(date);
	const daysReturn = addDays(dates, days);
	return formatInTimeZone(daysReturn, 'Australia/Sydney', 'dd/MM/yyyy');
};
export const getCountryCodePhone = number => {
	let countryCode = '';
	let startThree = number.substring(0, 3);
	if (startThree === '+61' || startThree === '+64') {
		countryCode = startThree;
		number = number.substring(4);
	} else {
		if (number.substring(0, 1) !== '0') {
			let indexVal = number.indexOf(0);
			countryCode = number.substring(0, indexVal);
			number = number.substring(indexVal);
		}
	}
	return {countryCode, number};
};

export const getAuctionItemImg = data => {
	if (data?.Product_Image?.image_url) {
		return data?.Product_Image?.image_url;
	} else {
		return '/assets/svg/placeholder.svg';
	}
};

export const formattedOrderStatus = status => {
	const name = {
		pending: 'Pending',
		// eslint-disable-next-line babel/camelcase
		partially_refunded: 'Partially Refunded',
		inprogress: 'In Progress',
		refunded: 'Refunded',
		delivered: 'Delivered',
		cancelled: 'Cancelled',
		shipped: 'Shipped',
		complete: 'Delivered',
		hold: 'On Hold',
	};
	return name[status];
};

export const convertTaxPrice = (price, rate) => {
	//console.log({price, rate});
	if (price && price > 0) {
		return Math.abs(Number(price) * (1 + Number(rate) / 100));
	} else {
		return (0).toFixed(2);
	}
};

export const reduceTaxPrice = (price, rate) => {
	if (!rate) rate = 0;
	if (price && price > 0) {
		return Math.abs(Number(price) / (1 + Number(rate) / 100));
	} else {
		return (0).toFixed(2);
	}
};

export const joinArray = (array, key) => {
	return array.join(`${key}`);
};
export const getAge = born => {
	const now = new Date();
	var birthday = new Date(now.getFullYear(), born.getMonth(), born.getDate());
	if (now >= birthday) return now.getFullYear() - born.getFullYear();
	else return now.getFullYear() - born.getFullYear() - 1;
};
export const ageVerified = () => {
	const session = sessionStorage && sessionStorage.getItem('ageVerification');
	if (session && Object.keys(JSON.parse(session)).length) {
		let obj = JSON.parse(session);
		return obj.is18 && obj.is18 === true ? true : false;
	} else {
		return false;
	}
};
