import {useRouter} from 'next/router';
import {useEffect} from 'react';

import {useApp} from '@context/AppProvider';

export const AuthGuard = ({children}) => {
	const router = useRouter();
	const {
		idb: {user, session},
		setRedirect,
	} = useApp();
	useEffect(() => {
		//auth is initialized and there is no user
		if (session && !user) {
			// remember the page that user tried to access
			setRedirect(router.route);
			// redirect
			router.push('/');
		}
	}, [router, user, setRedirect, session]);
	// if auth initialized with a valid user show protected page
	if (user) {
		return <>{children}</>;
	}
	//otherwise don't return anything, will do a redirect from useEffect */
	return null;
};
